<div class="modal-header">
    <h4 class="modal-title" [translate]="'AGUARDE'"></h4>
    <button *ngIf="tempo === 0" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
        <i class="mdi mdi-close"></i>
    </button>
  </div>
<div class="modal-content modal-milvus">
     <div class="modal-body">
        <div class="text-center align-middle" style="margin-top:10%">
            <h4 [translate]="'SOLICITANDO_CONEXAO'"> </h4>
            <div class="div-icons-connection">
                <i class="mdi mdi-laptop i-lt-user"></i>
                <svg class="i-arrow-connection" style="width:42px;height:42px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M14 16.94V12.94H5.08L5.05 10.93H14V6.94L19 11.94Z" />
                </svg>
                <svg style="width:42px;height:42px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M20 4C21.1 4 22 4.89 22 6V16C22 17.11 21.11 18 20 18H24V20H0V18H4C2.9 18 2 17.11 2 16V6C2 4.89 2.89 4 4 4H20M20 6H4V16H20V6M12 12C14.21 12 16 12.9 16 14V15H8V14C8 12.9 9.79 12 12 12M12 7C13.11 7 14 7.9 14 9S13.11 11 12 11 10 10.11 10 9 10.9 7 12 7Z" />
                </svg>
            </div>
        </div>
    </div>
</div>