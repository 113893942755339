import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario()}),
    params: {}
  };
  
  constructor(
    private tokenService: TokenService,
    private http: HttpClient
  ) { }

  getDropdownMoedas(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/empresas/moeda/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getInformacoesUsuario(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/empresas/verificar-informacoes-usuario`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  geSituacaoEmpresa(): Observable<any> {
   this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/empresas/situacao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getEmpresa(token?): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/empresas`, (token) ? { headers: new HttpHeaders({'authorization': token }) } : this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPlano(id, token?): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/empresas/plano/${id}`, (token) ? { headers: new HttpHeaders({'authorization': token }) } : this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getPlanoDropdown(): Observable<any> {
    this.httpOptions.params = { tela_trial: true };
    return this.http.get(`${this.apiUrl}api/empresas/dropDownPlanos`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getEmpresaConf(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/configuracao-empresa`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getEmpresLogo(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/empresas/getLogoUrl`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaEmpresLogo(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/empresas/atualiza/logo`, payload,
    { headers: new HttpHeaders({'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  ativarContaPremium(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/empresas/ativaContaPremium`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  contatoComercial(payload): Observable<any>{
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/empresas/solicita-contato-comercial`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  getUnidadesNegocio(): Observable<any> {
    this.httpOptions.params = "";    
    return this.http.get(`${this.apiUrl}api/clienteendereco/dropdown-empresa`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
