import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SimpleCrypt } from "ngx-simple-crypt";
import { ToastrService } from "ngx-toastr";
import * as io from "socket.io-client";
import { environment } from "src/environments/environment";
import { TokenService } from "../token/token.service";


export class configIO {
  static getConfigIO() {
    return {
      withCredentials: true,
      forceNew: true,
      reconnectionDelay: 10000,
      reconnectionDelayMax: 15000,
      transports: ['websocket', 'polling']
    }
  }
}

@Injectable({ providedIn: "root" })
export class WebsocketService {
  socket: any;
  connections: any;
  chatAbertoMsg: [];
  chatConectadoSocket: any;
  _this = this
  tokenData: any
  chatTela: any;
  conectado: boolean = true;
  tooMany: any;
  dadosChat: any;

  constructor(private toastr: ToastrService, public translate: TranslateService, private tokenService: TokenService) {
  }

  ngAfterViewInit() {
    this.tokenData = this.tokenService.getAllInfos();
  }

  ngOnInit() {
    this.tokenData = this.tokenService.getAllInfos();
  }

  playSoundAlert(): void {
    var playAlert = require('alert-sound-notify')
    playAlert()
    playAlert('purr')
    playAlert.volume(0.5)
  }

  getAlertaNotificacao(): any {
    return this.tokenService.getAllInfos();
  }

  connect() {
    var _this = this;
    this.chatTela = localStorage.getItem("@milvus:chatAberto");
    this.socket = io(environment.apiRealTimeUrl, {
      query: {
        token: this.getToken(),
        tipo: "usuario-portal",
      },
      ...configIO.getConfigIO()
    });

    this.socket.emit("ping_gestor", "Ping gestor..");

    this.socket.on("connect", function () {
      console.log("Socket - conectado!", new Date());
    });

    this.socket.on("disconnect", function () {
      console.error("Socket - desconectado!", new Date());
      _this.conectado = false;
    });

    this.socket.on('reconnect', function () {
      console.warn('Socket - reconectado!', new Date());
      _this.conectado = false;
    });

    this.socket.on("error", function (erro) {
      console.error("Socket - erro! " + erro, new Date());
    });

    this.socket.on('receive', function (data) {
      _this.tokenData = _this.getAlertaNotificacao();
      if (_this.tokenData.alerta_chat && _this.chatTela !== data.id) {
        _this.playSoundAlert();
        _this.toastr.success(
          data.nome_autor + ': ' + data.msg,
          _this.translate.instant('NOVA_MENSAGEM_CHAT'),
          {
            timeOut: 6000,
            closeButton: false,
            tapToDismiss: true,
            toastClass: "toastr-success-no-icon ngx-toastr",
          }
        );
        _this.criptografaVariavel('nova_mensagem');
      }
    });

    this.socket.on('nova_mensagem_chat_portal', function (data) {
      _this.tokenData = _this.getAlertaNotificacao();
      _this.criptografaVariavel('nova_mensagem');
      if (_this.tokenData.alerta_chat && _this.chatTela !== data.id) {
        _this.playSoundAlert();
        _this.toastr.success(
          data.nome_autor + ': ' + data.msg,
          _this.translate.instant('NOVA_MENSAGEM_CHAT'),
          {
            timeOut: 6000,
            closeButton: false,
            tapToDismiss: true,
            toastClass: "toastr-success-no-icon ngx-toastr",
          }
        );
        _this.criptografaVariavel('nova_mensagem');
      }
    });

    this.socket.on('nova_mensagem_chat_client', function (data) {
      _this.tokenData = _this.getAlertaNotificacao();
      if (_this.tokenData.alerta_chat && _this.chatTela !== data.id && data.status_chat !== 1) {
        _this.playSoundAlert();
        _this.toastr.success(
          data.nome_autor + ': ' + data.msg,
          _this.translate.instant('NOVA_MENSAGEM_CHAT'),
          {
            timeOut: 6000,
            closeButton: false,
            tapToDismiss: true,
            toastClass: "toastr-success-no-icon ngx-toastr",
          }
        );
        _this.criptografaVariavel('nova_mensagem_chat_client');
        function msg() {
          var notification = new Notification(_this.translate.instant('NOVA_MENSAGEM_CHAT') + ": " + data.nome_autor, {
            icon: 'https://portal.milvus.com.br/assets/img/icone_milvus.png',
            body: data.msg,
            requireInteraction: true
          });

          notification.onclick = function () {
            parent.focus();
            window.focus();
            this.close();
          };
        }
        if (Notification.permission !== "granted") {
          Notification.requestPermission()
            .then(function (result) {
              if (result === "granted") {
                msg();
              }
            });
        } else {
          msg();
        }
      }
    });

    this.socket.on("nova_conversa", function (data) {
      _this.tokenData = _this.getAlertaNotificacao();
      if (_this.tokenData.is_online_chat) {
        _this.playSoundAlert();
        _this.toastr.success(
          '',
          _this.translate.instant('NOVO_CHAT'),
          {
            timeOut: 6000,
            closeButton: false,
            tapToDismiss: true,
            toastClass: "toastr-success-no-icon ngx-toastr",
          }
        )
        _this.criptografaVariavel('nova_mensagem');
        function msg() {
          var notification = new Notification(_this.translate.instant('NOVO_CHAT'), {
            icon: 'https://portal.milvus.com.br/assets/img/icone_milvus.png',
            body: _this.translate.instant('NOVO_CHAT_AGUARDANDO_ATENDIMENTO'),
            requireInteraction: true
          });

          notification.onclick = function () {
            parent.focus();
            window.focus();
            this.close();
          };
        }
        if (Notification.permission !== "granted") {
          Notification.requestPermission()
            .then(function (result) {
              if (result === "granted") {
                msg();
              }
            });
        } else {
          msg();
        }
      }
    });

    this.socket.on("atualizacao_chat", function (data) {
      _this.tokenData = _this.getAlertaNotificacao();
      if (_this.tokenData && _this.tokenData.alerta_chat) {
        _this.criptografaVariavel('atualizacao_chat');
      }
    });

    this.socket.on('reconnecting', function (attempt) {
      console.warn('Socket - reconectando! - ' + attempt, new Date());
    });

    this.socket.on('reconnect_error', function (error) {
      console.error('Socket - reconnect_error! - ' + error, new Date());
    });

    return this.socket;
  }

  criptografaVariavel(variavel: string) {
    if (localStorage.getItem(variavel)) {
      localStorage.removeItem(variavel)
    }
    let encodedObjectAsString: string = SimpleCrypt.encodeDefault("szHYxd7tz", 'true')
    localStorage.setItem(variavel, encodedObjectAsString)
  }

  // OPERAÇÕES DA CHAT

  connectChat(dadosChat: any, userdata: any) {
    var _this = this;
    _this.tooMany = false;
    this.chatTela = localStorage.getItem("@milvus:chatAberto");
    this.tokenData = this.tokenService.getAllInfos();
    this.chatAbertoMsg = [];
    let _urlSocket = (dadosChat.url_socket_chat) ? dadosChat.url_socket_chat : environment.chatApi;
    let socket = io(_urlSocket, {
      ...configIO.getConfigIO()
    });
    this.dadosChat = dadosChat;

    socket.on('connect', function () {
      _this.conectado = socket.connected;
      console.log("Socket Chat - conectado!");

      socket.emit("login", {
        user: userdata.username,
        email: userdata.email,
        id: dadosChat._id,
        token_widget: dadosChat.widget.token_widget,
        tipo: "tecnico",
      });
    });

    socket.on('tooMany', function () {
      console.warn('Socket Chat - tooMany!');
      _this.toastr.show(_this.translate.instant('CONECTADO_OUTRA_ABA'), '', environment.toastrConfig('danger'));
      socket.disconnect();
      socket.close();
      _this.conectado = false;
    });

    socket.on('reconnecting', function (attempt) {
      console.warn('Socket Chat - reconectando! - ' + attempt, new Date());
    });

    socket.on('reconnect_error', function (error) {
      console.error('Socket Chat - reconnect_error! - ' + error, new Date());
    });

    socket.on('reconnect', function () {
      _this.conectado = socket.connected;
      console.warn('Socket Chat - reconectado!', new Date());
    });

    socket.on('chat_finalizado', function (data) {
      console.log('chat finalizado', new Date());
    });

    socket.on("disconnect", function () {
      _this.conectado = socket.connected;
      console.warn("Socket Chat - desconectado!", new Date());
    });

    socket.on("error", function (erro) {
      console.error('Socket Chat - erro: ' + erro, new Date());
    });

    this.connections = socket;
    this.chatConectadoSocket = socket;
    return socket;
  }

  connectSocketChat(userdata?: any) {
    if (!userdata) {
      userdata = this.getToken();
    }

    let socket = io(environment.chatApi, {
      ...configIO.getConfigIO()
    });

    socket.on('connect', function () {
      socket.emit('login', {
        user: userdata.username,
        email: userdata.email,
        id: "",
        token_widget: "",
        tipo: "tecnico"
      });
    });
  }

  addMsgChat(msg: any, callback: (mesagemLocal: any) => void) {
    let socket = this.connections;
    socket.emit('msg', msg, this.retornoMsg(msg, callback))
  }

  addSocketMsgChat(msg: any, socket: any, callback: (mesagemLocal: any) => void) {
    socket.emit('msg', msg, this.retornoMsg(msg, callback));
  }

  retornoMsg(_msg, callback) {
    let mesagemLocal = JSON.parse(JSON.stringify(_msg));
    this.msgNotSendAdd(mesagemLocal);
    return (data) => {
      if (data === 'OK') {
        this.msgNotSendCheck(mesagemLocal);
        mesagemLocal['status_mensagem'] = 1;
      } else if (data === 'FAIL') {
        mesagemLocal['status_mensagem'] = 4;
      } else if (typeof data === 'object') {
        mesagemLocal = { ...mesagemLocal, ...data };
      }
      callback(mesagemLocal);
    };
  }

  msgNotSendAdd(_msg) {
    var msgNotEnvied = this.getList();
    msgNotEnvied.push(_msg);
    this.setList(msgNotEnvied);
    return msgNotEnvied;
  }

  msgNotSendCheck(_msg) {
    var msgNotEnvied = this.getList();
    msgNotEnvied.splice(msgNotEnvied.indexOf(_msg), 1);
    this.setList(msgNotEnvied);
  }

  getList() {
    var item = localStorage.getItem((this.dadosChat._id + 'msgNotEnvied'));
    var msgNotEnvied = item ? JSON.parse(item) : [];
    return msgNotEnvied ? msgNotEnvied : [];
  }

  setList(msgNotEnvied) {
    localStorage.setItem(this.dadosChat._id + 'msgNotEnvied', JSON.stringify(msgNotEnvied));
  }

  getMsgs() {
    return this.chatAbertoMsg;
  }

  finishChat(chatData: any, userData: any) {
    let _urlSocket = (chatData.url_socket_chat) ? chatData.url_socket_chat : environment.chatApi;
    let socket = io(_urlSocket, {
      ...configIO.getConfigIO()
    });
    socket.on('connect', function () {
      var login = {
        user: userData.nome,
        id: chatData._id,
        token_widget: chatData.widget ? chatData.widget.token_widget : "",
        tipo: "tecnico"
      };
      socket.emit('login', login);
    });
    setTimeout(function () {
      socket.emit("chat_finalizado", function () {
        this.eventFinish(socket, chatData);
      });
      socket.close();
    }, 400);
  }

  eventFinish(socket: any, dadosChat: any): void {
    var _user = {
      msg: "#",
      user: dadosChat.user.nome,
      img: dadosChat.img,
      token_widget: dadosChat.token,
      id: dadosChat._id
    };
    socket.emit('msg', _user);
  }

  // geral
  getSocket() {
    return this.socket;
  }

  getToken() {
    return this.tokenService.getUsuario().token;
  }
}
