import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TokenService } from 'src/app/core/token/token.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PowershellService {
  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) { }

  requestPowershellConnection(deviceParams): Observable<any> {
    this.httpOptions.params = {};
    return this.http.post(`${this.apiUrl}api/power-shell/solicita-acesso`, deviceParams, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  closePowershellConnection(sessionParams): Observable<any> {
    this.httpOptions.params = {};
    return this.http.post(`${this.apiUrl}api/power-shell/encerra`, sessionParams, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getSessionCommandsPowershell(sessionParams): Observable<any> {
    this.httpOptions.params = {};
    return this.http.post(`${this.apiUrl}api/power-shell/lista-comandos`, sessionParams, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if(error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`
      )
    }
    return throwError(
      error.error
    );
  }
}
