import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter();  

  constructor() { }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation()
    this.fileOver = true;
    //console.log('Drag Over');    
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation()
    //console.log('Drag Leave');    
  }

  @HostListener('drop', ['$event']) ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation()
    
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      //console.log(`You dropped ${files.length} files`);
      this.fileDropped.emit(files);
    }
  }
}
