<div class="modal-header">
    <h6 class="modal-title" id="modal-title-default">{{'ABERTURA_DE_SUGESTOES' | translate}}</h6>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <i class="mdi mdi-close"></i>
    </button>
</div>
<div class="modal-body">
    <!-- <div *ngIf="carregando" class="d-flex align-items-center justify-content-center h-100 w-100">
        <span class="loader"></span>
    </div> -->
    <div data-featurebase-embed></div>
</div>
