<div class="btn-group d-block select-category-tree-father" dropdown #dropdown="bs-dropdown" [insideClick]="true" [ngClass]="{required: verifyRequired()}" [isDisabled]="disabled" [ngbTooltip]="selectedCategory ? selectedCategory.text : ''" [container]="modalContent ? '.modal' : ''" positionTarget="top">
    <button #btnSelectCatTree id="button-basic" [ngClass]="{'modal-content-select': modalContent}" dropdownToggle type="button" class="btn select-category-tree dropdown-toggle"
        aria-controls="dropdown-basic" (click)="verifyLoadResources()">
        <ng-container *ngIf="selectedCategory; else noItemSelected">
            <span container="body">{{selectedCategory.text | ellipsis: [ellipsisLabel]}}</span>
            <i class="fas fa-times clear-select-cat-btn" (click)="clear()"></i>
        </ng-container>
        <ng-template #noItemSelected>
            <span style="font-style: italic; color: #a1a1a1;">{{'SELECIONE' | translate}}</span>
        </ng-template>
        <span class="caret"></span>
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu drop-select-categories-tree"
        role="menu" aria-labelledby="button-basic">
        <cat-multi-dropdown 
            [dropCategories]="categories"
            [select]="true" 
            [clearFilter]="emitEventToCategoriesTree.asObservable()" 
            [selectOnlySecondary]="selectOnlySecondary"
            [showOnlyPrimary]="showOnlyPrimary"
            [showOnlySecondary]="showOnlySecondary"
            [primaryToFilter]="primaryId"
            [selectOnlyLastSub]="selectOnlyLastSub"
            (categoryChange)="setCategory($event); dropdown.hide()">
        </cat-multi-dropdown>
    </ul>
</div>