import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TokenService } from 'src/app/core/token/token.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { environment } from 'src/environments/environment';
import { ModalReenviarCodigoDoisFatoresComponent } from '../modal-reenviar-codigo-dois-fatores/modal-reenviar-codigo-dois-fatores.component';

@Component({
  selector: 'app-modal-codigo-autenticacao-dois-fatores',
  templateUrl: './modal-codigo-autenticacao-dois-fatores.component.html',
  styleUrls: ['./modal-codigo-autenticacao-dois-fatores.component.scss']
})
export class ModalCodigoAutenticacaoDoisFatoresComponent implements OnInit, OnDestroy {

  @Input() config;
  @Input() dispositivo;
  @Input() acesso_remoto_config;
  selector: string;
  newSubs: Subscription;
  codigoForm: UntypedFormGroup;
  email_codificado: string = '';

  constructor(
    private tokenService: TokenService,
    public activeModal: NgbActiveModal,
    private _fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private elemRef: ElementRef,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private usersService: UsuariosService
  ) {
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.buildForm();
    this.eventPaste();
    this.eventEnter();
    this.getEmail();
    if (this.config) {
      this.sendCode(this.config, false);
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('paste', this.eventPaste, false);
    document.removeEventListener('keypress', this.eventEnter, false);
    this.newSubs?.unsubscribe();
  }

  sendCode(config: string, is_send_code: boolean): void {
    let payload = {};
    if (config) {
      payload[config] = false;
    }
    this.updateConfigAuth(payload, is_send_code);
  }

  updateConfigAuth(payload: any, is_send_code: boolean): void {
    if (this.config) {
      this.newSubs = this.authenticationService.updateConfigAuth(payload).subscribe(
        {
          next: (result) => {
            if (is_send_code) {
              this.activeModal.close(payload);
            }

            if (result && result.codigo_invalido) {
              this.toastr.show(this.translate.instant('CODIGO_INCORRETO_AUTH'), '', environment.toastrConfig('danger'));
            }
          }, error: (error) => {
            console.error(error);
          }
        }
      );
    } else {
      this.activeModal.close(payload);
    }
  }

  getEmail(): void {
    let idUser = this.tokenService.getUsuario().id;
    let email = this.tokenService.getUsuario().email ? `${this.tokenService.getUsuario().email.substring(0, 2)}*******@${this.tokenService.getUsuario().email.split('@')[1]}` : this.translate.instant('NAO_POSSUI');
    this.email_codificado = email;

    this.newSubs = this.usersService.getUsuarioById(idUser).subscribe(
      (result) => {
        let _user = result.usuario;
        let email = _user.email ? `${_user.email.substring(0, 2)}*******@${_user.email.split('@')[1]}` : `${_user.username.substring(0, 2)}*******@${_user.username.split('@')[1]}`;
        this.email_codificado = email;
      },
      (error) => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    );
  }

  autoTab(field1, field2, event): void {
    if (field1 && (<HTMLInputElement>document.getElementById(field1)).value.length == 1 && event.event.keyCode !== 8) {
      document.getElementById(field2).focus();
    } else if (event.event.keyCode === 8 && event.field) {
      document.getElementById(event.field).focus();
    }
  }

  eventPaste(): void {
    var _this = this;
    document.addEventListener('paste', function (event) {
      let paste = (event.clipboardData).getData("text");
      _this.setValuesAuthCode(paste);
    }, false);
  }

  eventEnter(): void {
    var _this = this;
    document.addEventListener('keypress', function (e) {
      if (e.key === 'Enter' && !_this.codigoForm.invalid) {
        _this.confirmCode();
      }
    })
  }

  setValuesAuthCode(paste): void {
    if (paste) {
      var _arrAux = Array.from(paste);
      _arrAux = _arrAux.slice(0, _arrAux.length > 6 ? 5 : _arrAux.length)
      var cont = 0;
      for (const field in this.codigoForm.controls) {
        this.codigoForm.controls[field].setValue(
          this.checkValueArrayPaste(_arrAux[cont])
        );
        cont++;
      }
    }
  }

  checkValueArrayPaste(value): string {
    var _aux = '';
    if (value && !isNaN(value)) {
      _aux = value.toString()
    }
    return _aux;
  }

  concatCharCode(): string {
    var _auxString = ''
    for (const field in this.codigoForm.controls) {
      if (field) {
        _auxString += this.codigoForm.controls[field].value;
      }
    }
    return _auxString;
  }

  buildForm(): void {
    this.codigoForm = this._fb.group({
      validacao_primeiro: ["", Validators.required],
      validacao_segundo: ["", Validators.required],
      validacao_terceiro: ["", Validators.required],
      validacao_quarto: ["", Validators.required],
      validacao_quinto: ["", Validators.required],
      validacao_sexto: ["", Validators.required],
    });
  }

  confirmCode(): void {
    let payload = {
      codigo_dois_fatores: this.concatCharCode()
    };
    payload[this.config] = true;
    this.updateConfigAuth(payload, true);
  }

  resendCode(): void {
    const modalRef = this.modalService.open(ModalReenviarCodigoDoisFatoresComponent, {
      backdrop: "static",
      windowClass: "modal-custom-milvus",
      keyboard: true,
      size: "pequeno",
      scrollable: false,
      centered: true,
    });


    if (this.config) {
      let _config = {};
      _config[this.config] = false;
      modalRef.componentInstance.config = _config;
    }

    if (this.dispositivo) {
      modalRef.componentInstance.dispositivo = this.dispositivo;
    }

    if (this.acesso_remoto_config) {
      modalRef.componentInstance.acesso_remoto_config = this.acesso_remoto_config;
    }


    modalRef.result.then((result) => { });
  }

  close(result?): void {
    document.removeEventListener('paste', this.eventPaste, false)
    this.activeModal.close(result);
  }

}
