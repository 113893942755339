import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SimpleCrypt } from "ngx-simple-crypt";
import { ToastrService } from "ngx-toastr";
import { Observable, Subscription } from "rxjs";
import { WebsocketService } from "src/app/core/websocket/websocket.service";
import { DeviceService } from "src/app/services/device.service";
import { EmpresasService } from "src/app/services/empresas.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Component({
  selector: "app-viewer",
  templateUrl: "./viewer.component.html",
  styleUrls: ["./viewer.component.scss"],
})
export class ViewerComponent implements OnInit, OnDestroy {

  tempo: number = 50;
  countAbrir: number = 0;
  @Input() data;
  @Input() index;
  empresa: any;
  versao_client: any;
  socket: any;
  selector: string;
  newSubs: Subscription;
  interval: any;

  constructor(
    public activeModal: NgbActiveModal,
    private websocketService: WebsocketService,
    private deviceService: DeviceService,
    private empresasService: EmpresasService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private elemRef: ElementRef,
  ) {
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
  }

  conexaoAceita: boolean = false;

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85';
    this.zeraConexaoAceita();

    this.socket = this.websocketService.getSocket();

    this.setaSituacaoEmpresa();

    this.getVersaoClient();

    this.interval = setInterval(() => {
      if (this.tempo > 0) {
        this.tempo = this.tempo - 1;
        if (localStorage.getItem('conexaoAceita') === 'true') {
          this.close();
        }
      } else {
        this.close(false);
      }
    }, 1000);    

    // let disp = {
    //   "objSessao": {
    //     "dispositivo_id": this.data.dispositivo.id,
    //     "socket_id": this.socket.id
    //   }
    // }
    // this.conexao(disp);
    this.escutaEventSocket(this.data.connection);
  }
  
  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  zeraConexaoAceita(): void {
    localStorage.removeItem('conexaoAceita');
    let encodedObjectAsString: string = SimpleCrypt.encodeDefault("szHYxd7tz", 'false');
    localStorage.setItem('conexaoAceita', encodedObjectAsString);
  }

  setaSituacaoEmpresa(): void {
    this.newSubs = this.empresasService.getEmpresa().subscribe(
      result => {
        this.empresa = result.empresa;
      },
      error => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    );
  }

  getVersaoClient(): void {
    this.newSubs = this.deviceService.getById(this.data.dispositivo.id)
      .subscribe((data) => {
        this.versao_client = data.dispositivo.versao_client;
      }, error => {
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      });
  }

  conexaoRecusada(): void {
    this.close();
    this.toastr.show(this.translate.instant("MSG_CONEXAO_RECUSADA"), "", environment.toastrConfig("danger"));
    this.socket.off("resposta_conexao");
    localStorage.removeItem('viewerVnc');
    this.zeraConexaoAceita();
  }

  close(result?): void {
    clearInterval(this.interval);
    if(result === false) this.toastr.show(this.translate.instant("MSG_CONEXAO_RECUSADA"), "", environment.toastrConfig("danger"));
    this.activeModal.close(result);
  }

  getSocketEvent(): Observable<any>{
    let observable = new Observable(observer => {
      
      this.socket.on('resposta_conexao', (data) => {
        observer.next(data);
      });
      return () => {};
    });
    return observable;
  }

  // conexao(disp): void {        
  //   this.newSubs = this.deviceService.newRemoteSession(disp).subscribe((data) => {      
  //     this.escutaEventSocket(data);
  //   }, error => {
  //       this.toastr.show(error.error_msg.error_msg, "", environment.toastrConfig("danger"));
  //       this.close();
  //     }
  //   )
  // }

  conexaoRecusadaLinkGuacamoleVazio() {
    this.close();
    this.toastr.show(this.translate.instant("ACESSO_NAO_PODE_SER_COMPLETADO"), "", environment.toastrConfig("danger"));
    this.socket.off("resposta_conexao");
    localStorage.removeItem('viewerVnc');
    this.zeraConexaoAceita();
  }

  escutaEventSocket(data): void {     
    this.newSubs = this.getSocketEvent().subscribe(result => { 
      if (!result['is_aceito'] || result['is_aceito'] === false) {
        this.conexaoRecusada();
        return;
      }

      if(result['link_guacamole'] === '' || !result['link_guacamole']) {
        this.conexaoRecusadaLinkGuacamoleVazio();
        return;
      }

      this.configuraGuacamole(result, data.sessao.id);

      this.countAbrir = 0;
      if (this.countAbrir === 0) {
        this.countAbrir++;
        var janela = window.open(location.origin + '/remote-access', '_blank');
        if (janela === null || janela.closed || typeof janela.closed === 'undefined') {
          this.conexaoAceita = true;
          this.abrePopupBloqueado();
        } else {
          this.close();
        }
      }
    }, error => {
      console.log(error);      
      this.toastr.show(error.error_msg.error_msg, "", environment.toastrConfig("danger"));
      this.close();      
    })
  }

  configuraGuacamole(result, vncId): void {
    var urlCriptografada = "";
    if (result['link_guacamole']) {
      urlCriptografada = SimpleCrypt.encodeDefault("szHYxd7tz", result['link_guacamole']);
      localStorage.setItem('token_guacamole', result['token_acesso']);
      localStorage.setItem('showMenuGuacamole', 'true');
    } else {
      urlCriptografada = SimpleCrypt.encodeDefault("szHYxd7tz", result['link_novnc']);
      localStorage.setItem('showMenuGuacamole', 'false');
    }

    localStorage.setItem('viewerVnc', urlCriptografada);
    localStorage.setItem('viewerVncId', vncId);
  }

  abrePopupBloqueado(): void {
    Swal.fire({
      icon: 'error',
      title: 'Pop-up bloqueado',
      html: '<a href="' + location.origin + '/remote-access" target="_blank" style="color: #2dce89">' + location.origin + '/remote-access </a>'
    }).then(
      (result) => {
        if (result.isConfirmed) {
          localStorage.removeItem('conexaoAceita')
          let encodedObjectAsString: string = SimpleCrypt.encodeDefault("szHYxd7tz", 'true')
          localStorage.setItem('conexaoAceita', encodedObjectAsString)

          var url: string = location.origin.toString() + '/remote-access'
          window.open(url, '_blank');
          this.close();
        }
      }
    );
  }
}
