/// <reference types="@angular/localize" />

/*!

=========================================================
* Argon Dashboard PRO Angular - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-angular
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { ApplicationRef, enableProdMode, Inject } from "@angular/core";
import { enableDebugTools } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/markdown/markdown';
import * as Sentry from "@sentry/angular";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { registerLicense } from "@syncfusion/ej2-base";

registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH5ed3VQQmhcVkB2VkU=');

if (environment.production) {
  enableProdMode();
}

const enabledSentry = environment.ambiente !== 'local' && environment.ambiente !== 'prod' && window.location.hostname !== 'localhost';

if (enabledSentry) {
  console.log('Enabling Sentry');
  
  Sentry.init({
    dsn: "https://29c42e35638cfd6ea810b30a90239313@o313141.ingest.us.sentry.io/4507606870130688",
    release: environment.ambiente,
    environment: environment.ambiente,
    integrations: []
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
  .then(module => enableDebugTools(module.injector.get(ApplicationRef).components[0]))
  .catch(err => console.error(err));
