import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenService } from "../core/token/token.service";
import { WebsocketService } from "../core/websocket/websocket.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ViewerComponent } from "../components/modais/modal-Viewer/viewer.component";
import { AcessoRemotoPersonalizado } from "../models/acesso-remoto.model";
import { DeviceService } from "./device.service";
import { ToastrService } from "ngx-toastr";
import { ModalCodigoAutenticacaoDoisFatoresComponent } from "../components/modais/modal-codigo-autenticacao-dois-fatores/modal-codigo-autenticacao-dois-fatores.component";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class AcessoRemotoService {
  private apiUrl = environment.apiGestorUrlLocal;
  private apiVNC = environment.apiVNC;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      authorization: this.tokenService.getToken(),
      session: this.tokenService.getSessionUsuario()
    }),
    params: {},
  };
  socket;
  public messages: any;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private websocketService: WebsocketService,
    private modalService: NgbModal,
    private deviceService: DeviceService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  executarAcessoSSH(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/ssh/executa-acesso`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  setAcessoPersonalizado(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracao/acesso-remoto/personalizado`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  removeAcessoPersonalizado(query: AcessoRemotoPersonalizado): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
    if (query.empresa_id) params.set("empresaId", query.empresa_id.toString());
    if (query.dispositivo)
      params.set("dispositivoId", query.dispositivo.id.toString());
    if (query.grupoDispositivo)
      params.set("grupoId", query.grupoDispositivo.id.toString());
    this.httpOptions.params = "";
    return this.http
      .delete(
        `${this.apiUrl}api/configuracao/acesso-remoto/personalizado/${query.cliente.id}?${params}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getListagemPersonalizados(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/configuracao/acesso-remoto/personalizado/lista`,
        filtros,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getListagemClientes(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/configuracao/atualizacao/personalizado/lista`,
        filtros,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  setAdicionaPadrao(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracao/acesso-remoto/padrao`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getTogglePadrao(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/configuracao/acesso-remoto/padrao`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  verify2faRemoteAccess(params, index?): void {
    let socket = this.websocketService.getSocket();

    if (!socket) {
      console.error("não conectado");
      throw new Error("Não Conectado");
    }

    socket.off("resposta_conexao");

    let _firstPayload = {
      objSessao: {
        dispositivo_id: params.id,
        socket_id: socket.id
      }
    };

    this.deviceService.newRemoteSession2fa(_firstPayload)
      .subscribe({
        next: (results) => {
          if(results.possui_autenticacao_dois_fatores) {
            const modalRef = this.modalService.open(ModalCodigoAutenticacaoDoisFatoresComponent, {
              backdrop: "static",
              windowClass: "modal-custom-milvus",
              keyboard: true,
              scrollable: false,
              size: "medio",
              centered: true
            });

            modalRef.componentInstance.acesso_remoto_config = _firstPayload;
        
            modalRef.result.then(modalResult => {
              if(modalResult) {
                let _authPayload = {
                  objSessao: {
                    dispositivo_id: params.id,
                    socket_id: socket.id
                  },
                  codigo_dois_fatores: modalResult.codigo_dois_fatores
                };

                this.deviceService.newRemoteSession2fa(_authPayload)
                  .subscribe({
                    next: (posAuthResult) => {                      
                      if (posAuthResult && posAuthResult.codigo_invalido) {
                        this.toastr.show(this.translate.instant('CODIGO_INCORRETO_AUTH'), '', environment.toastrConfig('danger'));
                      } else {
                        this.abrirConexaoRemotaNova(params, posAuthResult, index);
                      }
                      
                    }, error: (error) => {
                      console.error(error);
                      this.toastr.show(error.error_msg, '', environment.toastrConfig('danger'));
                    }
                  });
              }
            });
          } else {
            this.abrirConexaoRemotaNova(params, results, index);
          }
        }, error: (error) => {
          console.error(error);
          let msg = error.error_msg.error_msg;
          if(msg === null){
            msg = error;
          }
          this.toastr.show(msg, '', environment.toastrConfig('danger'));
        }
      });
  }

  abrirConexaoRemotaNova(params, connection, index?): Observable<any> {
    const modalRef = this.modalService.open(ViewerComponent, {
      backdrop: "static",
      windowClass: "modal-custom-milvus",
      keyboard: true,
      scrollable: false,
      size: "medio",
    });

    let data = {
      dispositivo: params,
      connection: connection
    };

    modalRef.componentInstance.data = data;
    if (index) {
      modalRef.componentInstance.index = index;
    }
    modalRef.result.then((result) => {});

    return null;
  }

  transferirArquivo(params): Observable<any> {
    let httpOptions_guaca = {
      headers: new HttpHeaders({
        authorization: localStorage.getItem("token_guacamole"),
      }),
      params: {},
    };
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiVNC}apivnc/sessaoremota/transferir-arquivo`,
        params,
        httpOptions_guaca
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("Ops! Ocorreu um erro:", error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
          `o body é: ${error.error}`
      );
    }
    return throwError(error.error);
  }

  getTempoGravacao(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/gravacao-acesso-remoto/dropdown-tempo`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getConfigAcessoRemotoPadrao(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/configuracao/acesso-remoto/padrao`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getListagemAcessoRemotoGravacao(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}apivnc/sessaoremota/lista-historico-conexao`,
        filtros,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  aceitarCobrança(gravacao_tempo_id): Observable<any> {
    this.httpOptions.params = null;
    return this.http
      .put(
        `${this.apiUrl}api/configuracao/acesso-remoto/padrao`,
        {armazenamento_gravacao_tempo_id: gravacao_tempo_id, armazenamento_gravacao_is_aceitou: true },
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getQuantoCustaGravacao(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/gravacao-acesso-remoto/quanto-custa`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }
}
