<div class="modal-content">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">
            {{'AUTENTICACAO_DOIS_FATORES' | translate}}
        </h6>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="close()"
        >
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row mb-3">
            <div class="col-3">
                <img src="assets/img/icons/svg/icone-cadeado.svg" style="width: 100%;"/>
            </div>
            <div class="col-9">
                <p style="font-size: 14px !important;" [translate]="'AVISO_REENVIO_CODIGO_DOIS_FATORES'"></p>
                <br>
                <p style="font-size: 14px !important;">E-mail: {{email_codificado}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-end">
                <button class="btn btn-darkblue btn-md badge-pill" (click)="sendCodeEmail()" style="width: 200px;">
                    <span [translate]="'ENVIAR'"></span>
                </button>
            </div>
        </div>
    </div>
</div>