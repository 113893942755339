export interface Theme {
    name: string;
    properties: any;
}

export const light: Theme = {
    name: 'Light',
    properties: {
        "--background-default": "#fff",
        "--background-itens": "#fff",
        "--background-modal-ticket-chatgpt": "#F4F5F7",
        "--color-inside": "#5f5f5f",
        "--color-inside-modal-ticket-chatgpt": "#5f5f5f",        
        "--color-light-inside": "#e9ecef",
        "--color-dark-inside": "#e0e2e3",
        "--color-headers": "#414141",
        "--color-text-dark": "#777777",
        "--color-text-dark-black": "#000",
        "--color-dividers": "#dbdbdb",
        "--color-hover-dropdown-itens": "#ebf5ff",
        "--color-comum-areas" : "#f7f7f7",
        "--color-google-schedule": "#1e88e5",
        "--color-log-error": "#5f5f5f",

        "--background-alerts": "#fff",
        "--background-a-fazer": "#FF5555",
        "--background-atendendo": "#81DA58",
        "--background-pausado": "#FCCC48",
        "--background-retornados": "#58C6DA",
        "--background-expirados": "#E7A343",
        "--background-help": "#188AFF",
        "--background-info": "#11cdef",
        "--background-info-alerta": "#11cdef",
        "--background-info2": "#5E72E4",
        "--background-success-dark": "#03A79D",
        "--background-import-custom-report-button":"#fe9800",
        "--background-pink": "#e41fa9",
        "--background-purple": "#BE8DD2",
        "--background-mv-purple": "#800080",
        "--background-darkblue": "#3f73e9",
        "--background-darkgray": "#868686",
        "--background-warning": "#fb6340",
        "--background-success": "#2dce89",
        "--background-danger": "#f5365c",
        "--background-swal-btn-confirm": "#5E72E4",
        "--background-swal-btn-deny": "#FF5555",
        "--background-swal-btn-cancel": "#757575",
        "--background-light": "#dee2e6",
        "--background-btn-default": "#172b4d",
        "--border-btn-default": "#172b4d",
        "--background-primary": "#5e72e4",
        "--background-dakrpurple": "#9B549C",
        "--border-btn-outline-secondary": "#fff",
        "--background-btn-darkgreen": "#629B9C",
        "--background-td-stripped": "#D8D8D8",
        "--background-tr-stripped-red": "rgb(248, 187, 187)",
        "--background-datepicker-range": "#e9edf0",
        "--background-bg-lightness": "#fff",
        "--background-line-gantt-selected": "#e2e5f4",
        "--background-integration": "#ff9900",

        "--background-chat-agf": "#B56065",
        "--background-chat-agat": "#FB6340",
        "--background-chat-aten": "#268F62",
        "--background-chat-hoje": "#007A7D",
        "--background-vul-medio": "#FFB346",
        "--border-vul-critico": "#404040",
        "--background-sla-prestes": "#B6B6B6",
        "--background-link-http": "#757575",
        "--background-chat-msg-origem": "#d9f2db",
        "--background-chat-msg-destino": "#e2e2e2",
        "--background-hover-condicoes-filtros": "#eeeeee",
        "--background-hover-drop-opt-filtros": "#b6ccff",
        "--background-user-sem-foto": "#ccc9c9",

       "--blockquote-theme-gpt": "#F4F5F7",
        "--color-default": "#000000",
        "--color-see-more-transcription": "#2278FA",
        "--color-a-fazer": "#fff",
        "--color-atendendo": "#fff",
        "--color-pausado": "#fff",
        "--color-retornados": "#fff",
        "--color-expirados": "#fff",
        "--color-alert": "#fff",
        "--color-help": "#fff",
        "--color-info": "#fff",
        "--color-info-alerta": "#fff",
        "--color-info2": "#fff",
        "--color-success-dark": "#fff",
        "--color-pink": "#fff",
        "--color-purple": "#fff",
        "--color-mv-purple": "#fff",
        "--color-darkblue": "#fff",
        "--color-darkgray": "#fff",
        "--color-outline-darkblue": "#fff",
        "--color-warning": "#fff",
        "--color-success": "#fff",
        "--color-danger": "#fff",
        "--color-notificacao": "#fff",
        "--color-shadow": "#c7c7c7",
        "--color-lbl-success": "#fff",
        "--color-lbl-warning": "#fff",
        "--color-lbl-danger": "#fff",
        "--color-lbl-help": "#fff",
        "--color-lbl-info": "#fff",
        "--color-lbl-task-entrega": "#fff",
        "--color-lbl-task-retirada": "#fff",
        "--color-linha-ticket-hover": "#e8f0f9",
        "--color-device-row-hover": "#e8f0f9",
        "--color-table-borders": "#dfdfdf",
        "--color-disabled": "#cfcfcf",
        "--color-btn-default": "#fff",
        "--color-btn-primary": "#fff",
        "--color-darkpurple": "#fff",
        "--color-darkgreen": "#fff",
        "--color-integration": "#fff",

        "--color-dash-agatendimento": "#fff",
        "--color-dash-afazer": "#fff",
        "--color-dash-expirados": "#fff",
        "--color-dash-internos": "#fff",
        "--color-dash-externos": "#fff",
        "--color-dash-pausados": "#fff",
        "--color-dash-retidos": "#fff",
        "--color-dash-retornados": "#fff",
        "--color-dash-conferencia": "#fff",
        "--color-dash-meus": "#fff",
        "--color-dash-abertos": "#fff",
        "--color-dash-sla-estourados": "#fff",
        "--color-dash-sla-prestes": "#fff",
        "--color-dash-sla-sem-cat": "#fff",
        "--color-dash-sla-pausados": "#fff",
        "--color-dash-inventario-disp": "#fff",
        "--color-dash-inventario-alertas": "#fff",
        "--color-dash-disp-critico": "#fff",
        "--color-dash-link-todos": "#fff",
        "--color-dash-users-on": "#fff",
        "--color-dash-users-off": "#fff",
        "--color-chat-agf": "#fff",
        "--color-chat-agat": "#fff",
        "--color-chat-aten": "#fff",
        "--color-chat-hoje": "#fff",
        "--color-vul-medio": "#fff",
        "--color-sla-prestes": "#fff",
        "--color-datepicker-weekdays": "#9aaec1",
        "--color-datepicker-week": "#54708b",
        "--color-input-search-filtros": "#dcdcdc8f",
        "--color-condicoes-filtros": "#dedede",
        "--color-info-chat-transferencia": "#004eff",

        "--box-shadow-tables": "#d7d7d7",
        "--box-shadow-card": "0 0 2rem 0 rgb(134 134 134 / 15%)",
        "--box-shadow-modal-content": "0 15px 35px rgb(50 50 93 / 20%), 0 5px 15px rgb(0 0 0 / 17%)",
        "--card-hover-shadow": "0 0 4rem 7px rgb(134 134 134 / 34%)",

        "--color-exit-button": "#2F7BEE",
        "--border-exit-button": "#fff",

        "--opacity-disabled": "1",
        "--opacity-dash-icons": "1",
        "--opacity-border-dash-icons": "0",

        "--background-icon-view-gantt": "url(../../assets/img/icons/svg/icone_projetos_branco.svg)",
        "--background-editor-mention": "#0341341a",
        "--color-editor-mention": "#489dff",
        "--brightness-template-editor": "0.1",
        "--background-msg-erro-chat": "#f8d7da",
        "--background-explicacao-erro-chat": "#f7e9ea"
    }
};

export const dark: Theme = {
    name: 'Dark',
    properties: {
        "--background-default": "#3b3b3b",
        "--background-itens": "#585858",
        "--background-modal-ticket-chatgpt": "#3b3b3b",
        "--color-inside": "#c7c7c7",
        "--color-inside-modal-ticket-chatgpt": "#c7c7c7",
        "--color-light-inside": "#525252",
        "--color-dark-inside": "#4e4d4d",
        "--color-headers": "#c7c7c7",
        "--color-text-dark": "#c7c7c7",
        "--color-text-dark-black": "#fff",
        "--color-dividers": "#646464",
        "--color-hover-dropdown-itens": "#3f73e991",
        "--color-comum-areas" : "#525252",
        "--color-google-schedule": "#fff",
        "--color-log-error": "#404040",

        "--background-alerts": "#565656",
        "--background-a-fazer": "transparent",
        "--background-atendendo": "transparent",
        "--background-pausado": "transparent",
        "--background-retornados": "transparent",
        "--background-expirados": "transparent",
        "--background-help": "transparent",
        "--background-info": "transparent",
        "--background-info-alerta": "#11cdef",
        "--background-info2": "transparent",
        "--background-success-dark": "transparent",
        "--background-pink": "transparent",
        "--background-purple": "transparent",
        "--background-mv-purple": "transparent",
        "--background-darkblue": "transparent",
        "--background-darkgray": "transparent",
        "--background-warning": "transparent",
        "--background-success": "transparent",
        "--background-import-custom-report-button": "transparent",
        "--background-danger": "transparent",
        "--background-swal-btn-confirm": "#565656",
        "--background-swal-btn-deny": "#565656",
        "--background-swal-btn-cancel": "#565656",
        "--background-light": "transparent",
        "--background-btn-default": "transparent",
        "--border-btn-default": "#2460c7",
        "--background-primary": "transparent",
        "--background-dakrpurple": "transparent",
        "--border-btn-outline-secondary": "#4385b1",
        "--background-btn-darkgreen": "transparent",
        "--background-td-stripped": "#767676",
        "--background-tr-stripped-red": "rgb(98 75 75)",
        "--background-datepicker-range": "#747474",
        "--background-bg-lightness": "#3a3a3a",
        "--background-line-gantt-selected": "#3b3e4d",
        "--background-integration": "transparent",

        "--background-chat-agf": "transparent",
        "--background-chat-agat": "transparent",
        "--background-chat-aten": "transparent",
        "--background-chat-hoje": "transparent",
        "--background-vul-medio": "transparent",
        "--border-vul-critico": "#000000",
        "--background-sla-prestes": "transparent",
        "--background-link-http": "transparent",
        "--background-chat-msg-origem": "#3a4a3c",
        "--background-chat-msg-destino": "#606060",
        "--background-hover-condicoes-filtros": "#2f2f2f",
        "--background-hover-drop-opt-filtros": "#4a5775",
        "--background-user-sem-foto": "#606060",

        "--color-default": "#fff",
        "--color-see-more-transcription": "#0728ff",
        "--color-a-fazer": "#FF5555",
        "--color-atendendo": "#81DA58",
        "--color-pausado": "#FCCC48",
        "--color-retornados": "#58C6DA",
        "--color-expirados": "#E7A343",
        "--color-alert": "#FCCC48",
        "--color-help": "#fff",
        "--color-info": "#11cdef",
        "--color-info-alerta": "#fff",
        "--color-info2": "#5E72E4",
        "--color-success-dark": "#03A79D",
        "--color-pink": "#e41fa9",
        "--color-purple": "#BE8DD2",
        "--color-mv-purple": "#800080",
        "--color-darkblue": "#3f73e9",
        "--color-darkgray": "#c7c7c7",
        "--color-outline-darkblue": "#fff",
        "--color-warning": "#fb6340",
        "--color-success": "#2dce89",
        "--color-danger": "#f5365c",
        "--color-notificacao": "#3f73e9",
        "--color-shadow": "#2e2424",
        "--color-lbl-success": "#56ce89",
        "--color-lbl-warning": "#FBB040",
        "--color-lbl-danger": "#c72c4e",
        "--color-lbl-info": "#2aa5c4",
        "--color-lbl-task-entrega": "#5E72E4",
        "--color-lbl-task-retirada": "#9B549C",
        "--color-linha-ticket-hover": "#2f343a",
        "--color-device-row-hover": "#2f343a",
        "--color-table-borders": "#2a2a2a",
        "--color-disabled": "#565656",
        "--color-btn-default": "#2460c7",
        "--color-btn-primary": "#5e72e4",
        "--color-darkpurple": "#9B549C",
        "--color-darkgreen": "#629B9C",
        "--color-integration": "#ff9900",

        "--color-dash-agatendimento": "#a67a81",
        "--color-dash-afazer": "rgb(255, 85, 85)",
        "--color-dash-expirados": "#E7A343",
        "--color-dash-internos": "#2BB96B",
        "--color-dash-externos": "#53D58E",
        "--color-dash-pausados": "#FCCC48",
        "--color-dash-retidos": "#B35757",
        "--color-dash-retornados": "#58C6DA",
        "--color-dash-conferencia": "#F572A8",
        "--color-dash-meus": "#41A5F6",
        "--color-dash-abertos": "#81C04D",
        "--color-dash-sla-estourados": "#FF5555",
        "--color-dash-sla-prestes": "#FC7C5F",
        "--color-dash-sla-sem-cat": "#9B549C",
        "--color-dash-sla-pausados": "#FCCC48",
        "--color-dash-inventario-disp": "#81C04D",
        "--color-dash-inventario-alertas": "#FF5555",
        "--color-dash-disp-critico": "#000000",
        "--color-dash-link-todos": "#388E3C",
        "--color-chat-agf": "#B56065",
        "--color-chat-agat": "#FB6340",
        "--color-chat-aten": "#268F62",
        "--color-chat-hoje": "#007A7D",
        "--color-vul-medio": "#FFB346",
        "--color-dash-users-on": "#268F62",
        "--color-dash-users-off": "#FB6340",
        "--color-sla-prestes": "#B6B6B6",
        "--color-datepicker-weekdays": "#dceeff",
        "--color-datepicker-week": "#97afc7",
        "--color-input-search-filtros": "#585858",
        "--color-condicoes-filtros": "#4f4f4f",
        "--color-info-chat-transferencia": "#9cbaff",

        "--box-shadow-tables": "#262626",
        "--box-shadow-card": "0 0 2rem 0 rgb(32 32 32 / 86%)",
        "--box-shadow-modal-content": "0 15px 35px rgb(28 28 28 / 40%), 0 5px 15px rgb(0 0 0 / 30%)",
        "--card-hover-shadow": "0 0 4rem 7px rgb(0 0 0 / 88%)",

        "--color-exit-button": "#2F7BEE",
        "--border-exit-button": "#2F7BEE",

        "--opacity-disabled": "0.3",
        "--opacity-dash-icons": "0",
        "--opacity-border-dash-icons": "1",

        "--background-icon-view-gantt": "url(../../assets/img/icons/svg/icone_projetos_azul.svg)",
        "--background-editor-mention": "#0341341a",
        "--color-editor-mention": "#48ffda",
        "--brightness-template-editor": "1.5",
        "--background-msg-erro-chat": "#f8d7da",
        "--background-explicacao-erro-chat": "#f7e9ea"
    }
};