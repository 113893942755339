import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TokenService } from 'src/app/core/token/token.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DeviceService } from 'src/app/services/device.service';
import { PowershellService } from 'src/app/services/powershell.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-reenviar-codigo-dois-fatores',
  templateUrl: './modal-reenviar-codigo-dois-fatores.component.html',
  styleUrls: ['./modal-reenviar-codigo-dois-fatores.component.scss']
})
export class ModalReenviarCodigoDoisFatoresComponent implements OnInit, OnDestroy {
  
  selector: string;
  newSubs: Subscription;
  email_codificado: string = '';
  @Input() config;
  @Input() modulo_id;
  @Input() dispositivo;
  @Input() acesso_remoto_config;
  @Input() email_login;
  @Input() payload;

  constructor(
    private toastr: ToastrService,
    private elemRef: ElementRef,
    public activeModal: NgbActiveModal,
    private translate: TranslateService,
    private tokenService: TokenService,
    private authenticationService: AuthenticationService,
    private usersService: UsuariosService,
    private modalService: NgbModal,
    private powershellService: PowershellService,
    private deviceService: DeviceService
  ) { 
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
  }

  ngOnInit(): void {
    document.getElementsByTagName(this.selector)[0]['style']['zoom'] = '0.85'; 
    this.getEmail();   
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  getEmail(): void {    
    let usuario = this.tokenService.getUsuario()
    if (usuario && usuario.id && !this.email_login) {
      let idUser = usuario.id;
      let email = usuario.email ? `${usuario.email.substring(0,2)}*******@${usuario.email.split('@')[1]}` : this.translate.instant('NAO_POSSUI');    
      this.email_codificado = email;
      this.newSubs = this.usersService.getUsuarioById(idUser).subscribe(
        (result) => {  
          let _user = result.usuario;
          let email = _user.email ? `${_user.email.substring(0,2)}*******@${_user.email.split('@')[1]}` :`${_user.username.substring(0,2)}*******@${_user.username.split('@')[1]}`;    
          this.email_codificado = email;
        },
        (error) => {
          this.toastr.show(error, "", environment.toastrConfig("danger"));
        }
      );
    } else {
      this.email_codificado = this.email_login ? `${this.email_login.substring(0,2)}*******@${this.email_login.split('@')[1]}` : this.translate.instant('NAO_POSSUI'); 
    }    
  }

  close(): void {
    this.activeModal.close();
  }

  get2FAAuth(payload: any): void {
    this.newSubs = this.authenticationService.get2FAAuth(payload).subscribe(
      {
        next: (result) => {
          this.close();
        }, error: (error) => {
          console.error(error);          
        }
      }
    )
  }

  sendCodeEmail(): void {
    if (this.config && !this.config.hasOwnProperty('login'))  {
      this.newSubs = this.authenticationService.updateConfigAuth(this.config).subscribe(
        {
          next: (result) => {
            this.get2FAAuth(this.payload)
            this.close();
          }, error: (error) => {
            console.error(error);          
          }
        }
      );
    } else if (this.modulo_id) {
      let payload = {
        modulo_id: this.modulo_id
      };
  
      this.get2FAAuth(payload);
    } else if (this.dispositivo) {
      this.checkAuthenticationPowershell(this.dispositivo);
    } else if (this.acesso_remoto_config) {
      this.checkAuthenticationRemoteConnection(this.acesso_remoto_config);
    } else if (this.config && this.config.hasOwnProperty('login')) {      
      this.activeModal.close(this.config);
    } else {
      this.close();
    }
  }  

  checkAuthenticationRemoteConnection(config: any): void {
    this.newSubs = this.deviceService.newRemoteSession2fa(config)
      .subscribe({
        next: (posAuthResult) => {
          this.activeModal.close();          
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error_msg, '', environment.toastrConfig('danger'));
        }
      });
  }
  
  checkAuthenticationPowershell(dispositivo: any): void {
    if (dispositivo.is_agent === true && dispositivo.tipo_agent_id === 1) {
      let payload = {
        objSessao: {
          dispositivo_id: dispositivo.id
        }
      }
      this.newSubs = this.powershellService.requestPowershellConnection(payload).subscribe(
        {
          next: (result) => {            
            this.activeModal.close(result);
          }, error: (error) => {
            console.error(error);     
            this.toastr.show(error, '', environment.toastrConfig('danger'));       
          }
        }
      )
    } 
  }
}
