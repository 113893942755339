import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';
import { iGetDropDownCliente } from '../interfaces/dashboards/dashboards-dashboard.interface';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  private apiUrl = environment.apiGestorUrlLocal;
  private apiUrlNova = environment.apiGestorUrlNova;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  uploadImagem(file): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/base-conhecimento/adicionaArquivo`, file,
      { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  criarCliente(cliente): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/clientes`, cliente, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaCliente(cliente): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clientes/novo`, cliente, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getListagemClientes(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/clientes/listGrid`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getClientes(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/clientes/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getById(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/clientes/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAlertasCliente(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/configuracao-alerta/cliente/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  atualizaAlertaPorCliente(payload, idCliente): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/configuracao-alerta/cliente/${idCliente}`, payload, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken()}),
      params: {}
    })
  }

  getDropdownTimezone(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/usuarios/timezones/dropdown`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  ativarCliente(cliente_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clientes/ativar/${cliente_id}`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  desativarCliente(cliente_id, motivo): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clientes/inativar/${cliente_id}`, motivo, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  excluirCliente(cliente_id, excluir_chamados): Observable<any> {
    this.httpOptions.params = "";
    return this.http.request('delete', `${this.apiUrl}api/clientes/${cliente_id}`, {
      body: excluir_chamados,
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() })
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  gerarTokenCliente(cliente_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/clientes/token/${cliente_id}`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  exportarClienteXls(relatorio): Observable<any> {
    return this.http.post(`${this.apiUrl}api/clientes/exportar/xls`, relatorio, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
      responseType: 'blob'
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  importarClientesCsv(file): Observable<any> {
    var formData = new FormData;
    formData.append('file', file.file, file.file.name);
    return this.http.post(`${this.apiUrl}api/clientes/importacaoClientesCSV/${file.delimitador}`, formData, { headers: new HttpHeaders({ 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) })
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropDownCliente(filtro, params): Observable<iGetDropDownCliente> {
    this.httpOptions.params = params;
    return this.http.post<iGetDropDownCliente>(`${this.apiUrl}api/clientes/dropdown-paginado`, filtro, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscaGatilhosPorUsuario(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/seguir-cliente/lista`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  buscaGatilhosPorUsuarioPadrao(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/seguir-cliente/padrao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  buscaGatilhosPorUsuarioTodos(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrl}api/seguir-cliente/todos`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
  updateGatilhosPorUsuarioPadrao(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/seguir-cliente/padrao`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  seguirCliente(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/seguir-cliente`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deixarSeguirCliente(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.delete(`${this.apiUrl}api/seguir-cliente/personalizado/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  editarClienteSeguido(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/seguir-cliente/editar/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownPaises(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/pais-configuracao/dropdownlist`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  sendConvite(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/email-convite/send-convite`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getListConnectDevice(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(`${this.apiUrlNova}manager/user/devices`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }

  removeConnectDevice(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.delete(`${this.apiUrlNova}manager/user/devices/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
