<div class="modal-content">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">
            {{'AUTENTICACAO_DOIS_FATORES' | translate}}
        </h6>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="close()"
        >
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row" style="margin-top: -13px;margin-bottom: -18px;">
            <div class="col-3 p-0">
                <img src="assets/img/icons/svg/icone-cadeado.svg" style="width: 100%;"/>
            </div>
            <div class="col-9" style="margin: 57px auto; padding: 0;">
                <span style="font-size: 18px;">
                    {{ "DIGITE_CODIGO_ENVIADO_EMAIL_COFRE_SENHAS" | translate }} <br>
                    {{ "DIGITE_CODIGO_ENVIADO_EMAIL_COFRE_SENHAS_EMAIL" | translate : { email: email_codificado} }} <br>
                    {{ "POR_FAVOR_VERIFIQUE_EMAIL" | translate }}
                </span>
               
            </div>
        </div>
        <div class="row">
            <form [formGroup]="codigoForm" style="margin-top: -20px;">
                <div class="row d-flex justify-content-around mx-auto" style="max-width: 540px;">
                    <input
                        type="text" 
                        class="form-control col-1 padding-cell" 
                        maxlength="1" 
                        minlength="1"
                        formControlName="validacao_primeiro"
                        id="validacao_primeiro"
                        onkeypress="return (event.charCode !=8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))"
                        (keyup)="autoTab('validacao_primeiro', 'validacao_segundo', {event: $event, field: null});"
                    >
                    <input
                        type="text" 
                        class="form-control col-1 padding-cell" 
                        maxlength="1" 
                        minlength="1"
                        formControlName="validacao_segundo"
                        id="validacao_segundo"
                        onkeypress="return (event.charCode !=8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))"
                        (keyup)="autoTab('validacao_segundo', 'validacao_terceiro', {event: $event, field: 'validacao_primeiro'});"
                    >
                    <input
                        type="text" 
                        class="form-control col-1 padding-cell" 
                        maxlength="1" 
                        minlength="1"
                        formControlName="validacao_terceiro"
                        id="validacao_terceiro"
                        onkeypress="return (event.charCode !=8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))"
                        (keyup)="autoTab('validacao_terceiro', 'validacao_quarto', {event: $event, field: 'validacao_segundo'});"
                    >
                    <input
                        type="text" 
                        class="form-control col-1 padding-cell" 
                        maxlength="1" 
                        minlength="1"
                        formControlName="validacao_quarto"
                        id="validacao_quarto"
                        onkeypress="return (event.charCode !=8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))"
                        (keyup)="autoTab('validacao_quarto', 'validacao_quinto', {event: $event, field: 'validacao_terceiro'});"
                    >
                    <input
                        type="text" 
                        class="form-control col-1 padding-cell" 
                        maxlength="1" 
                        minlength="1"
                        formControlName="validacao_quinto"
                        id="validacao_quinto"
                        onkeypress="return (event.charCode !=8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))"
                        (keyup)="autoTab('validacao_quinto', 'validacao_sexto', {event: $event, field: 'validacao_quarto'});"
                    >
                    <input
                        type="text" 
                        class="form-control col-1 padding-cell" 
                        maxlength="1" 
                        minlength="1"
                        formControlName="validacao_sexto"
                        id="validacao_sexto"
                        onkeypress="return (event.charCode !=8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))"
                        (keyup)="autoTab(null, null, {event: $event, field: 'validacao_quinto'});"
                    >
                </div>
            </form>
            <div class="col-12 text-center" style="margin-top: 30px;">
                <button class="btn btn-warning btn-md badge-pill" style="width: 230px;"
                    (click)="resendCode()">
                    <span [translate]="'REENVIAR_CODIGO'"></span>
                </button>
              
                <button class="btn btn-darkblue btn-md badge-pill" [disabled]="codigoForm.invalid" style="width: 230px;"
                  (click)="confirmCode()">
                    <span [translate]="'CONFIRMAR'"></span>
                </button>
            </div>
        </div>
    </div>
</div>
