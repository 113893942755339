import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TokenService } from 'src/app/core/token/token.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { ThemeService } from 'src/app/shared/themes/theme.service';

@Component({
  selector: 'app-modal-feature-base',
  templateUrl: './modal-feature-base.component.html',
  styleUrls: ['./modal-feature-base.component.scss'],
})
export class ModalFeatureBaseComponent implements OnInit, OnDestroy {

  @Input() url!: string
  private jwtToken!: string;
  private returnTo!: string;
  private organization = 'milvus';
  private theme = 'light';
  private initialPage = 'Board';
  newSubs!: Subscription;

  
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private activeModal: NgbActiveModal,
    private tokenService: TokenService,
    private translate: TranslateService,
    private tema: ThemeService
  ) {}
    
  ngOnInit(): void { 
    const urlParams = new URLSearchParams(new URL(this.url).search);
    this.jwtToken = urlParams.get('jwt');
    this.returnTo = urlParams.get('return_to');
  }

  ngAfterViewInit(): void {
    this.openFeatureBase()
  }

  ngOnDestroy(): void {
    this.newSubs.unsubscribe();
  }

  openFeatureBase() {
    let idioma = this.translate.getLangs()[this.tokenService.getUsuario().idioma - 1];

    if (idioma === 'pt') idioma = 'pt-BR';

    let tema = this.tema.getActiveTheme().name.toLocaleLowerCase()

    window['Featurebase']('embed', {
      organization: this.organization,
      theme: tema,
      initialPage: this.initialPage,
      locale: idioma,
      hideMenu: false,
      hideLogo: false,
      jwtToken: this.jwtToken
    });

    setTimeout(() => {
      const iframe = this.elementRef.nativeElement.querySelector('#featurebase-embed-iframe');
      if (iframe) {
        this.renderer.setStyle(iframe, 'height', 'calc(100dvh - 130px)');
        this.renderer.setStyle(iframe, 'min-height', 'unset');
      }
    }, 1500);
  }

  close(): void {
    this.activeModal.close();
  }

}
