<div class="row" style="margin-bottom: 10px">
  <div class="col-xl-12">
    <div
      class="div-filters"
      id="div-filters"
      [ngClass]="{
        'shadow-filters':
          tipoFiltro !== 'listagemDashboardTecnico' &&
          tipoFiltro !== 'listagemDashboardChat' &&
          tipoFiltro !== 'listagemDashboardUsersStatus' &&
          tipoFiltro !== 'listagemDashboardPrincipal' &&
          tipoFiltro !== 'listagemDashboardSla' &&
          tipoFiltro !== 'listagemDashboardVulnerabilidade' &&
          tipoFiltro !== 'listagemDashboardDashboard' &&
          tipoFiltro !== 'listagemDashboardMesas' &&
          tipoFiltro !== 'listagemDashboardTicket' &&
          tipoFiltro !== 'listagemDashboardInventario' &&
          tipoFiltro !== 'listagemDashboardClassificacaoInterna' &&
          tipoFiltro !== 'listagemDashboardTarefas' &&
          tipoFiltro !== 'listagemDashBoardMonitoramentoLink' &&
          tipoFiltro !== 'listagemFiltroAvancadoTickets' &&
          tipoFiltro !== 'listagemDashboardClassificacaoTicketCliente' &&
          tipoFiltro !== 'listagemCanaisAtendimento' &&
          tipoFiltro !== 'listagemDashboardAprovacao',
        'no-shadow':
          tipoFiltro === 'listagemDashboardTecnico' ||
          tipoFiltro === 'listagemDashboardChat' ||
          tipoFiltro === 'listagemDashboardUsersStatus' ||
          tipoFiltro === 'listagemDashboardPrincipal' ||
          tipoFiltro === 'listagemDashboardSla' ||
          tipoFiltro === 'listagemDashboardVulnerabilidade' ||
          tipoFiltro === 'listagemDashboardDashboard' ||
          tipoFiltro === 'listagemDashboardMesas' ||
          tipoFiltro === 'listagemDashboardTicket' ||
          tipoFiltro === 'listagemDashboardInventario' ||
          tipoFiltro === 'listagemDashboardClassificacaoInterna' ||
          tipoFiltro === 'listagemDashboardTarefas' ||
          tipoFiltro === 'listagemDashBoardMonitoramentoLink' ||
          tipoFiltro === 'listagemFiltroAvancadoTickets' ||
          tipoFiltro === 'listagemDashboardClassificacaoTicketCliente' ||
          tipoFiltro === 'listagemCanaisAtendimento' ||
          tipoFiltro === 'listagemDashboardAprovacao'
      }"
    >
      <div class="d-flex inside-filters" id="div-inside-filters">
        <div
          class="d-flex"
          style="position: relative"
          *ngFor="let filtro of arrayFiltrosRecebidos; let i = index"
        >
          <div *ngIf="verificaVisibilidade(filtro) && filtro.tipo !== 'pesquisa'">
            <div
              style="height: 18px"
              *ngIf="filtro.tipo === 'filtro_avancado'"
            ></div>
            <div
              class="badge-filtros"
              *ngIf="filtro.tipo !== 'filtro_avancado'"
              style="margin-top: 4px"
              [ngClass]="{
                'filtros-recuados':
                  tipoFiltro !== 'listagemDashboardTecnico' &&
                  tipoFiltro !== 'listagemDashboardChat' &&
                  tipoFiltro !== 'listagemDashboardUsersStatus' &&
                  tipoFiltro !== 'listagemDashboardPrincipal' &&
                  tipoFiltro !== 'listagemDashboardSla' &&
                  tipoFiltro !== 'listagemDashboardVulnerabilidade' &&
                  tipoFiltro !== 'listagemDashboardDashboard' &&
                  tipoFiltro !== 'listagemDashboardMesas' &&
                  tipoFiltro !== 'listagemDashboardTicket' &&
                  tipoFiltro !== 'listagemDashboardInventario' &&
                  tipoFiltro !== 'listagemDashboardClassificacaoInterna' &&
                  tipoFiltro !== 'listagemDashboardTarefas' &&
                  tipoFiltro !== 'listagemDashBoardMonitoramentoLink' &&
                  tipoFiltro !== 'listagemFiltroAvancadoTickets' &&
                  tipoFiltro !==
                    'listagemDashboardClassificacaoTicketCliente' &&
                  tipoFiltro !== 'listagemCanaisAtendimento' &&
                  tipoFiltro !== 'listagemDashboardAprovacao'
              }"
            >
              <span
                *ngIf="filtro.selecionados > 0"
                class="badge badge-sm badge-circle badge-floating badge-default border-white"
                >{{ filtro.selecionados }}</span
              >
              <div *ngIf="!filtro.selecionados > 0" style="height: 24px"></div>
            </div>
            <a
              class="text-filters"
              href="javascript:void(0)"
              (click)="openDropdown(i, filtro)"
              *ngIf="filtro.tipo !== 'filtro_avancado'"
              [ngClass]="{ 'mt-filtros': filtro.selecionados > 0 }"
              [ngStyle]="isRelatorios && isRelatorios === 17 ? isOperadorEquipe(filtro) : isMesaEquipe(filtro)"
              style="display: flex; margin: 0px 0px 0px 9px"
            >
              <span class="nome-filtro" style="margin-right: 9px"
                >{{ filtro.nome }}
                <span
                  style="color: red"
                  *ngIf="
                    ((filtro.nome === translate.instant('MOEDA') ||
                      filtro.nome === translate.instant('PARCELA_AVULSA')) &&
                      tipoFiltro === 'filtroRelatorioFaturamentoSintetico') ||
                    ((filtro.nome === translate.instant('MOEDA') ||
                      filtro.nome === translate.instant('PARCELA_AVULSA')) &&
                      tipoFiltro === 'filtroRelatorioFaturamentoAnalitico')
                  "
                  >*</span
                >
              </span>
              <i
                id="{{ 'icon-' + i }}"
                class="fas fa-angle-down icon-filtro"
                style="color: #3f73e9; font-size: 18px"
              ></i>
            </a>
            <a
              class="text-filters"
              href="javascript:void(0)"
              (click)="openDropdown(i, filtro)"
              *ngIf="filtro.tipo === 'filtro_avancado'"
              [ngClass]="{ 'mt-filtros': filtro.selecionados > 0 }"
              [ngStyle]="isRelatorios && isRelatorios === 17 ? isOperadorEquipe(filtro) : isMesaEquipe(filtro)"
            >
              {{
                filtros.nome_filtro
                  ? (filtros.nome_filtro | ellipsis : 20)
                  : filtro.nome
              }}
            </a>
          </div>
          <div *ngIf="filtro.tipo === 'pesquisa'" class="input-search-filtros">
            <i class="fas fa-search"></i>
            <input class="form-control input-pesquisa-filtros" [(ngModel)]="filtro.modelValue" placeholder="{{ 'BUSCAR' | translate }}" type="text" name="search" autocomplete="off" container="body" [ngbTooltip]="filtro.customPlaceholder ? filtro.customPlaceholder : ('TOOLTIP_BUSCA_FILTROS' | translate)" placement="bottom" (keyup.enter)="setValorFiltroPesquisa(filtro)" />
          </div>
          <div
            *ngIf="filtro.tipo === 'input'"
            id="{{ 'dd-' + i }}"
            class="filtro-dropdown"
          >
            <input
              class="form-control mr-3"
              id="{{ 'inputText' + i }}"
              type="text"
              style="height: 35px; width: 250px"
              autocomplete="off"
              [ngClass]="{'w-390': filtro.customPlaceholder ? true : false}"	
              placeholder="{{ (filtro.customPlaceholder ? filtro.customPlaceholder : 'ENTER_PARA_BUSCAR') | translate }}"
              [(ngModel)]="filtro.modelValue"
              (keypress)="setValorFiltroInput(filtro, i, false, $event)"
            />
            <button
              class="btn btn-sm btn-darkblue"
              (click)="setValorFiltroInput(filtro, i, true)"
            >
              Ok
            </button>
          </div>
          <div
            *ngIf="filtro.tipo === 'dropdown'"
            id="{{ 'dd-' + i }}"
            class="filtro-dropdown"
          >
            <div
              *ngIf="filtro.data && filtro.data.length > 0"
              class="dropdown-opt-filtros"
            >
              <div
                *ngIf="
                  tipoFiltro === 'categoryCatalogueList' ||
                  tipoFiltro === 'categoryGroupsList'
                "
              >
                <ejs-dropdowntree
                  id="dropdowntree"
                  [fields]="dropDownFields"
                  placeholder="{{ 'SELECIONE' | translate }}"
                ></ejs-dropdowntree>
              </div>
              <div
                *ngIf="
                  tipoFiltro !== 'categoryCatalogueList' ||
                  tipoFiltro !== 'categoryGroupsList'
                "
              >
                <input
                  *ngIf="filtro.showInputText !== false"
                  id="{{ 'searchDropdown' + i }}"
                  type="text"
                  class="form-control"
                  placeholder="{{ 'PESQUISAR' | translate }}"
                  (keyup)="filtrarApi(searchFilter, filtro, i)"
                  [(ngModel)]="searchFilter"
                  style="height: 35px; margin-bottom: 10px"
                  autocomplete="off"
                />
                <a
                  *ngIf="filtro.selecionados > 0 && filtro.showInputText !== false"
                  class="opt0filtros"
                  href="javascript:void(0)"
                  (click)="desmarcarTodos(i, filtro)"
                >
                  <span
                    class="nome-filtro"
                    style="padding-left: 77px; color: red"
                    [translate]="'DESMARCAR_TODOS'"
                  ></span>
                </a>
                <div
                  class="opt-div-filtros"
                  *ngFor="
                    let opt of filtro.data
                      | filter : (searchFilter | removeAcentos);
                    let j = index
                  "
                  (click)="setValorFiltro(i, opt.id, filtro, opt.id)"
                >
                  <a
                    *ngIf="opt.id !== 'ver_mais'"
                    id="{{ 'opt-' + i + '-' + opt.id }}"
                    class="opt-filtros"
                    href="javascript:void(0)"
                    placement="bottom"
                    [title]="opt.text?.length > 15 ? opt.text : ''"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    container="body"
                    style="
                      width: 100%;
                      height: 100%;
                      text-align: center;
                      margin-top: 15px;
                    "
                    [ngClass]="{'opt-all': opt.id === '__all__'}"
                  >
                    <span class="nome-filtro">{{
                      opt.text | ellipsis : 30
                    }}</span>
                  </a>
                  <a
                    href="javascript:void(0)"
                    (click)="
                      verMaisDropdownClientes(filtro); $event.stopPropagation()
                    "
                    *ngIf="opt.id === 'ver_mais'"
                    style="
                      color: #4cb96d; 
                      font-weight: bold; 
                      width: 100%;
                      height: 100%;
                      text-align: center;
                      margin-top: 15px;
                    "
                    >{{ opt.text }}</a
                  >
                  <ng-container *ngIf="opt.tipo">
                    <div [ngSwitch]="opt.tipo" style="margin-left: 10px">
                      <i
                        container="body" [ngbTooltip]="'Chat'"
                        *ngSwitchCase="1"
                        class="far fa-comment"
                      ></i>
                      <i
                        container="body" [ngbTooltip]="'WhatsApp'"
                        *ngSwitchCase="2"
                        class="fab fa-whatsapp"
                      ></i>
                      <div container="body" [ngbTooltip]="'Chat/WhatsApp'" *ngSwitchCase="3">
                        <i class="far fa-comment"></i>
                        <i class="fab fa-whatsapp ml-2"></i>
                      </div>
                      
                      <span container="body" [ngbTooltip]="'Telegram'" *ngSwitchCase="4">
                        <g class="icon-cifra-circular">
                          <svg  style="margin-bottom: 5px;" width="17" viewBox="0 0 25 23" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.8765 0.00204882C22.5152 0.0177633 22.1837 0.121664 21.8925 0.236454C21.6234 0.342416 20.6572 0.748517 19.1187 1.39383C17.5801 2.03914 15.5415 2.89474 13.3684 3.80869C9.02233 5.63658 4.13845 7.69272 1.63595 8.74585C1.55001 8.78165 1.201 8.8885 0.815534 9.18291C0.429172 9.47801 0 10.1185 0 10.8189C0 11.3839 0.282054 11.9593 0.622638 12.2888C0.963223 12.6182 1.3084 12.7723 1.59688 12.887V12.8846C2.57204 13.2731 5.48419 14.4384 6.17755 14.7158C6.42349 15.4529 7.47931 18.6081 7.73048 19.4015H7.72804C7.90558 19.9631 8.07863 20.3291 8.31894 20.6346C8.43909 20.7873 8.58252 20.9257 8.75845 21.035C8.82595 21.0769 8.90069 21.1095 8.97576 21.14C8.98622 21.1445 8.997 21.1456 9.0075 21.1498L8.9782 21.1425C8.99997 21.1512 9.0195 21.1641 9.04169 21.1718C9.07784 21.1842 9.10289 21.1838 9.15156 21.1937C9.32284 21.2464 9.49381 21.2816 9.65212 21.2816C10.3317 21.2816 10.7484 20.9129 10.7484 20.9129L10.7753 20.8934L13.7103 18.4102L17.3045 21.7358C17.3701 21.8285 17.8844 22.5245 19.0747 22.5245C19.785 22.5245 20.3465 22.1729 20.7058 21.8042C21.0651 21.4354 21.2889 21.0581 21.3895 26467L21.3919 20.5442C21.4721 20.1315 24.9129 2.85398 24.9129 2.85398L24.9055 2.88328C25.0126 2.40528 25.0442 1.94303 24.9177 1.47196C24.7912 1.0009 24.4498 0.541181 24.0387 0.299939C23.6276 0.0586967 23.2377 -0.0136662 22.8765 0.00204882ZM22.3832 2.73922C22.2473 3.42184 19.1651 18.9064 18.9746 19.8776L13.7835 15.0748L10.2748 18.0415L11.2466 14.2422C11.2466 14.2422 17.9506 7.45611 18.3544 7.06106C18.6794 6.74477 18.7475 6.63389 18.7475 6.52388C18.7475 6.37761 18.6722 6.27238 18.4985 6.27238C18.3422 6.27238 18.13 6.42213 18.0174 6.49214C16.5877 7.38351 10.4981 10.8538 7.5034 12.5574C7.32318 12.4851 4.61745 11.3986 3.15958 10.8164C5.75275 9.72504 10.1979 7.85485 14.3378 6.11367C16.5104 5.19988 18.5485 4.34351 20.0856 3.69881C21.3831 3.15461 22.0599 2.87283 22.3832 2.73922ZM18.938 20.0559H18.9404L18.938 20.0632C18.9396 20.0551 18.9368 20.0622 18.938 20.0559Z"/>
                          </svg>    
                        </g>
                      </span>

                      <div container="body" [ngbTooltip]="'Chat/Telegram'" *ngSwitchCase="5">
                        <i class="far fa-comment mr-2"></i>
                        <span>
                          <g class="icon-cifra-circular">
                            <svg  style="margin-bottom: 5px;"  width="17" viewBox="0 0 25 23" xmlns="http://www.w3.org/2000/svg">
                              <path d="M22.8765 0.00204882C22.5152 0.0177633 22.1837 0.121664 21.8925 0.236454C21.6234 0.342416 20.6572 0.748517 19.1187 1.39383C17.5801 2.03914 15.5415 2.89474 13.3684 3.80869C9.02233 5.63658 4.13845 7.69272 1.63595 8.74585C1.55001 8.78165 1.201 8.8885 0.815534 9.18291C0.429172 9.47801 0 10.1185 0 10.8189C0 11.3839 0.282054 11.9593 0.622638 12.2888C0.963223 12.6182 1.3084 12.7723 1.59688 12.887V12.8846C2.57204 13.2731 5.48419 14.4384 6.17755 14.7158C6.42349 15.4529 7.47931 18.6081 7.73048 19.4015H7.72804C7.90558 19.9631 8.07863 20.3291 8.31894 20.6346C8.43909 20.7873 8.58252 20.9257 8.75845 21.035C8.82595 21.0769 8.90069 21.1095 8.97576 21.14C8.98622 21.1445 8.997 21.1456 9.0075 21.1498L8.9782 21.1425C8.99997 21.1512 9.0195 21.1641 9.04169 21.1718C9.07784 21.1842 9.10289 21.1838 9.15156 21.1937C9.32284 21.2464 9.49381 21.2816 9.65212 21.2816C10.3317 21.2816 10.7484 20.9129 10.7484 20.9129L10.7753 20.8934L13.7103 18.4102L17.3045 21.7358C17.3701 21.8285 17.8844 22.5245 19.0747 22.5245C19.785 22.5245 20.3465 22.1729 20.7058 21.8042C21.0651 21.4354 21.2889 21.0581 21.3895 26467L21.3919 20.5442C21.4721 20.1315 24.9129 2.85398 24.9129 2.85398L24.9055 2.88328C25.0126 2.40528 25.0442 1.94303 24.9177 1.47196C24.7912 1.0009 24.4498 0.541181 24.0387 0.299939C23.6276 0.0586967 23.2377 -0.0136662 22.8765 0.00204882ZM22.3832 2.73922C22.2473 3.42184 19.1651 18.9064 18.9746 19.8776L13.7835 15.0748L10.2748 18.0415L11.2466 14.2422C11.2466 14.2422 17.9506 7.45611 18.3544 7.06106C18.6794 6.74477 18.7475 6.63389 18.7475 6.52388C18.7475 6.37761 18.6722 6.27238 18.4985 6.27238C18.3422 6.27238 18.13 6.42213 18.0174 6.49214C16.5877 7.38351 10.4981 10.8538 7.5034 12.5574C7.32318 12.4851 4.61745 11.3986 3.15958 10.8164C5.75275 9.72504 10.1979 7.85485 14.3378 6.11367C16.5104 5.19988 18.5485 4.34351 20.0856 3.69881C21.3831 3.15461 22.0599 2.87283 22.3832 2.73922ZM18.938 20.0559H18.9404L18.938 20.0632C18.9396 20.0551 18.9368 20.0622 18.938 20.0559Z" />
                            </svg> 
                          </g>   
                        </span>
                      </div>
                      <div container="body" [ngbTooltip]="'Chat/WhatsApp/Telegram'" *ngSwitchCase="6">
                        <i class="far fa-comment"></i>
                        <i class="fab fa-whatsapp mx-2"></i>
                        <span>
                          <g class="icon-cifra-circular">
                            <svg  style="margin-bottom: 5px;" width="17" viewBox="0 0 25 23" xmlns="http://www.w3.org/2000/svg">
                              <path d="M22.8765 0.00204882C22.5152 0.0177633 22.1837 0.121664 21.8925 0.236454C21.6234 0.342416 20.6572 0.748517 19.1187 1.39383C17.5801 2.03914 15.5415 2.89474 13.3684 3.80869C9.02233 5.63658 4.13845 7.69272 1.63595 8.74585C1.55001 8.78165 1.201 8.8885 0.815534 9.18291C0.429172 9.47801 0 10.1185 0 10.8189C0 11.3839 0.282054 11.9593 0.622638 12.2888C0.963223 12.6182 1.3084 12.7723 1.59688 12.887V12.8846C2.57204 13.2731 5.48419 14.4384 6.17755 14.7158C6.42349 15.4529 7.47931 18.6081 7.73048 19.4015H7.72804C7.90558 19.9631 8.07863 20.3291 8.31894 20.6346C8.43909 20.7873 8.58252 20.9257 8.75845 21.035C8.82595 21.0769 8.90069 21.1095 8.97576 21.14C8.98622 21.1445 8.997 21.1456 9.0075 21.1498L8.9782 21.1425C8.99997 21.1512 9.0195 21.1641 9.04169 21.1718C9.07784 21.1842 9.10289 21.1838 9.15156 21.1937C9.32284 21.2464 9.49381 21.2816 9.65212 21.2816C10.3317 21.2816 10.7484 20.9129 10.7484 20.9129L10.7753 20.8934L13.7103 18.4102L17.3045 21.7358C17.3701 21.8285 17.8844 22.5245 19.0747 22.5245C19.785 22.5245 20.3465 22.1729 20.7058 21.8042C21.0651 21.4354 21.2889 21.0581 21.3895 26467L21.3919 20.5442C21.4721 20.1315 24.9129 2.85398 24.9129 2.85398L24.9055 2.88328C25.0126 2.40528 25.0442 1.94303 24.9177 1.47196C24.7912 1.0009 24.4498 0.541181 24.0387 0.299939C23.6276 0.0586967 23.2377 -0.0136662 22.8765 0.00204882ZM22.3832 2.73922C22.2473 3.42184 19.1651 18.9064 18.9746 19.8776L13.7835 15.0748L10.2748 18.0415L11.2466 14.2422C11.2466 14.2422 17.9506 7.45611 18.3544 7.06106C18.6794 6.74477 18.7475 6.63389 18.7475 6.52388C18.7475 6.37761 18.6722 6.27238 18.4985 6.27238C18.3422 6.27238 18.13 6.42213 18.0174 6.49214C16.5877 7.38351 10.4981 10.8538 7.5034 12.5574C7.32318 12.4851 4.61745 11.3986 3.15958 10.8164C5.75275 9.72504 10.1979 7.85485 14.3378 6.11367C16.5104 5.19988 18.5485 4.34351 20.0856 3.69881C21.3831 3.15461 22.0599 2.87283 22.3832 2.73922ZM18.938 20.0559H18.9404L18.938 20.0632C18.9396 20.0551 18.9368 20.0622 18.938 20.0559Z"/>
                            </svg>   
                          </g> 
                        </span>
                      </div> 
                      <div container="body" [ngbTooltip]="'Telegram/WhatsApp'" *ngSwitchCase="7">
                        <span>
                            <g class="icon-cifra-circular">
                              <svg style="margin-bottom: 5px;" width="17" viewBox="0 0 25 23" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.8765 0.00204882C22.5152 0.0177633 22.1837 0.121664 21.8925 0.236454C21.6234 0.342416 20.6572 0.748517 19.1187 1.39383C17.5801 2.03914 15.5415 2.89474 13.3684 3.80869C9.02233 5.63658 4.13845 7.69272 1.63595 8.74585C1.55001 8.78165 1.201 8.8885 0.815534 9.18291C0.429172 9.47801 0 10.1185 0 10.8189C0 11.3839 0.282054 11.9593 0.622638 12.2888C0.963223 12.6182 1.3084 12.7723 1.59688 12.887V12.8846C2.57204 13.2731 5.48419 14.4384 6.17755 14.7158C6.42349 15.4529 7.47931 18.6081 7.73048 19.4015H7.72804C7.90558 19.9631 8.07863 20.3291 8.31894 20.6346C8.43909 20.7873 8.58252 20.9257 8.75845 21.035C8.82595 21.0769 8.90069 21.1095 8.97576 21.14C8.98622 21.1445 8.997 21.1456 9.0075 21.1498L8.9782 21.1425C8.99997 21.1512 9.0195 21.1641 9.04169 21.1718C9.07784 21.1842 9.10289 21.1838 9.15156 21.1937C9.32284 21.2464 9.49381 21.2816 9.65212 21.2816C10.3317 21.2816 10.7484 20.9129 10.7484 20.9129L10.7753 20.8934L13.7103 18.4102L17.3045 21.7358C17.3701 21.8285 17.8844 22.5245 19.0747 22.5245C19.785 22.5245 20.3465 22.1729 20.7058 21.8042C21.0651 21.4354 21.2889 21.0581 21.3895 26467L21.3919 20.5442C21.4721 20.1315 24.9129 2.85398 24.9129 2.85398L24.9055 2.88328C25.0126 2.40528 25.0442 1.94303 24.9177 1.47196C24.7912 1.0009 24.4498 0.541181 24.0387 0.299939C23.6276 0.0586967 23.2377 -0.0136662 22.8765 0.00204882ZM22.3832 2.73922C22.2473 3.42184 19.1651 18.9064 18.9746 19.8776L13.7835 15.0748L10.2748 18.0415L11.2466 14.2422C11.2466 14.2422 17.9506 7.45611 18.3544 7.06106C18.6794 6.74477 18.7475 6.63389 18.7475 6.52388C18.7475 6.37761 18.6722 6.27238 18.4985 6.27238C18.3422 6.27238 18.13 6.42213 18.0174 6.49214C16.5877 7.38351 10.4981 10.8538 7.5034 12.5574C7.32318 12.4851 4.61745 11.3986 3.15958 10.8164C5.75275 9.72504 10.1979 7.85485 14.3378 6.11367C16.5104 5.19988 18.5485 4.34351 20.0856 3.69881C21.3831 3.15461 22.0599 2.87283 22.3832 2.73922ZM18.938 20.0559H18.9404L18.938 20.0632C18.9396 20.0551 18.9368 20.0622 18.938 20.0559Z"/>
                              </svg>  
                            </g>
                        </span>
                        <i class="fab fa-whatsapp ml-2"></i>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div
              *ngIf="filtro.data && filtro.data.length === 0"
              class="dropdown-opt-filtros"
            >
              <span [translate]="'NENHUM_ITEM'"></span>
            </div>
          </div>
          <div
            *ngIf="filtro.tipo === 'date'"
            id="{{ 'dd-' + i }}"
            class="filtro-datepicker date"
          >
            <div style="display: grid">
              <span class="text-center">
                <datepicker-padrao
                  [inline]="true"
                  [model]="filtro.selectedDate"
                  (dateChange)="
                    setValorFiltroDate(filtro, $event); closeAllDropdowns()
                  "
                ></datepicker-padrao>
              </span>
              <span>
                <button
                  type="button"
                  class="btn btn-success badge-pill"
                  [translate]="'LIMPAR'"
                  (click)="
                    filtro.selectedDate = null;
                    filtro.selecionados = 0;
                    setValorFiltroDate(filtro, null)
                  "
                  style="width: 100px; margin: 10px 0px"
                ></button>
              </span>
            </div>
          </div>
          <div
            *ngIf="filtro.tipo === 'dateRange'"
            id="{{ 'dd-' + i }}"
            class="filtro-datepicker filtro-datepicker-ranged date"
          >
            <div style="display: grid">
              <span class="text-center">
                <datepicker-padrao
                  [is_range]="true"
                  [inline]="true"
                  [model]="filtro.selectedDate"
                  (dateChange)="
                    setValorFiltroRangeDate(filtro, $event); closeAllDropdowns()
                  "
                ></datepicker-padrao>
              </span>
              <span class="text-right mr-3">
                <button
                  type="button"
                  class="btn btn-success badge-pill"
                  [translate]="'LIMPAR'"
                  (click)="
                    filtro.selectedDate = null;
                    filtro.selecionados = 0;
                    setValorFiltroDate(filtro, null)
                  "
                  style="width: 100px; margin: 10px 0px"
                ></button>
              </span>
            </div>
          </div>
          <div *ngIf="filtro.tipo === 'filtro_avancado'">
            <!-- <button class="btn btn-sm btn-outline-default-milvus small-circled-button badge-pill text-17"
                        (click)="cleanFilters()"><i class="fas fa-times"></i></button> -->
            <button
              type="button"
              class="btn btn-sm small-circled-btn text-17 btn-outline-default-milvus"
              style="margin-left: 0 !important; padding-top: 5px; top: 9px"
              (click)="cleanFilters()"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div
            *ngIf="filtro.tipo === 'tree'"
            id="{{ 'dd-' + i }}"
            class="filtro-dropdown dropdown-categories"
          >
            <div
              *ngIf="filtro.data && filtro.data.length > 0"
              class="dropdown-opt-filtros"
            >
              <cat-multi-dropdown [dropCategories]="filtro.data" 
                [clearFilter]="emitEventToCategoriesTree.asObservable()" 
                (categoryChange)="setValueFilterCategory($event, filtro)">
              </cat-multi-dropdown>
            </div>
            <div
              *ngIf="filtro.data && filtro.data.length === 0"
              class="dropdown-opt-filtros"
            >
              <span [translate]="'NENHUM_ITEM'"></span>
            </div>
            <!-- <milvus-tree-padrao [tipo]="'treeCategories'" [treeItems]="filtro.data" [expandida]="false" [mostraPesquisa]="true"></milvus-tree-padrao> -->
          </div>
          <!-- btn btn-sm btn-outline-default btn-outline-default-milvus small-circled-button badge-pill -->
        </div>
      </div>
      <div
        class="filtro-controls d-flex align-items-center gap-2"
        *ngIf="tipoFiltro !== 'listagemFiltroAvancadoTickets'"
        style="margin-left: 7px; margin-top: 11px"
        [ngClass]="{
          'filtros-recuados':
            tipoFiltro !== 'listagemDashboardTecnico' &&
            tipoFiltro !== 'listagemDashboardChat' &&
            tipoFiltro !== 'listagemDashboardUsersStatus' &&
            tipoFiltro !== 'listagemDashboardPrincipal' &&
            tipoFiltro !== 'listagemDashboardSla' &&
            tipoFiltro !== 'listagemDashboardVulnerabilidade' &&
            tipoFiltro !== 'listagemDashboardDashboard' &&
            tipoFiltro !== 'listagemDashboardMesas' &&
            tipoFiltro !== 'listagemDashboardTicket' &&
            tipoFiltro !== 'listagemDashboardInventario' &&
            tipoFiltro !== 'listagemDashboardClassificacaoInterna' &&
            tipoFiltro !== 'listagemDashboardTarefas' &&
            tipoFiltro !== 'listagemDashBoardMonitoramentoLink' &&
            tipoFiltro !== 'listagemFiltroAvancadoTickets' &&
            tipoFiltro !== 'listagemDashboardClassificacaoTicketCliente' &&
            tipoFiltro !== 'listagemCanaisAtendimento' &&
            mostrarTotalRegistros,
            'm-0': mostrarTotalRegistros
        }"
      >
        <div
          [disableTooltip]="
            !verificaFiltroRelatorioFaturamentoSinteticoAnalitico()
          "
          container="body" [ngbTooltip]="
            verificaTipoFiltro()
              ? ('AVISO_FILTRO_RELATORIO_FATURAMENTO_SINTETICO' | translate)
              : ''
          "
        >
          <button
            [disabled]="verificaFiltroRelatorioFaturamentoSinteticoAnalitico()"
            class="btn btn-sm btn-outline-default btn-outline-default-milvus small-circled-button clean-filters"
            (click)="cleanFilters()"
            style="border-radius: 22px; width: 29px"
            [ngClass]="{'m-0': mostrarTotalRegistros}"
          >
            <b class="clean-filters">x</b>
          </button>
        </div>
        <div
          [disableTooltip]="
            !verificaFiltroRelatorioFaturamentoSinteticoAnalitico()
          "
          container="body" [ngbTooltip]="
            verificaTipoFiltro()
              ? ('AVISO_FILTRO_RELATORIO_FATURAMENTO_SINTETICO' | translate)
              : ''
          "
        >
          <button
            [disabled]="verificaFiltroRelatorioFaturamentoSinteticoAnalitico()"
            class="btn btn-sm btn-outline-default btn-outline-default-milvus small-circled-button badge-pill"
            (click)="sendFilters()"
            [ngClass]="{
              'filtrar-recuado':
                tipoFiltro !== 'listagemDashboardTecnico' &&
                tipoFiltro !== 'listagemDashboardChat' &&
                tipoFiltro !== 'listagemDashboardUsersStatus' &&
                tipoFiltro !== 'listagemDashboardPrincipal' &&
                tipoFiltro !== 'listagemDashboardSla' &&
                tipoFiltro !== 'listagemDashboardVulnerabilidade' &&
                tipoFiltro !== 'listagemDashboardDashboard' &&
                tipoFiltro !== 'listagemDashboardMesas' &&
                tipoFiltro !== 'listagemDashboardTicket' &&
                tipoFiltro !== 'listagemDashboardInventario' &&
                tipoFiltro !== 'listagemDashboardClassificacaoInterna' &&
                tipoFiltro !== 'listagemDashboardTarefas' &&
                tipoFiltro !== 'listagemDashBoardMonitoramentoLink' &&
                tipoFiltro !== 'listagemFiltroAvancadoTickets' &&
                tipoFiltro !== 'listagemDashboardClassificacaoTicketCliente' &&
                tipoFiltro !== 'listagemCanaisAtendimento' &&
                tipoFiltro !== 'listagemDashboardAprovacao' &&
                mostrarTotalRegistros,
                'm-0': mostrarTotalRegistros
            }"
          >
            <b>{{ "FILTRAR" | translate }}</b>
          </button>
        </div>
        <div *ngIf="mostrarTotalRegistros">
          <select #selectTotalRegistros [(ngModel)]="totalRegistros" data-bs-theme="dark" class="form-select rounded-pill shadow-none" 
              style="font-size: 13px; border-color: #C4C4C4 !important; --bs-form-select-bg-img: unset;
              background-color: var(--background-default); padding: 4px 30px 3px 14px; color: var(--color-inside)"
              (ngModelChange)="sendFilters()"
            >
              <option [value]="4">4</option>
              <option [value]="10">10</option>
              <option [value]="20">20</option>
            </select>
            <span class="position-absolute" style="right: 10px; top: 1px; z-index: 9;">
              <i style="font-size: 10px" class="fas fa-chevron-down"></i>
            </span>
        </div>
      </div>
    </div>
  </div>
</div>
